import { BooleanField, useRecordContext } from 'react-admin';

import InfoSummary from './InfoSummary';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useFormatPrice } from '@js/hooks/useFormatPrice';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

type Props = {
    source?: string;
    record?: SelloSale | SelloOrderRow;
    resource?: string;
    sortable?: boolean;
    addLabel?: boolean;
    label?: string;
};

const SelloSaleAdditionalInfoField = (props: Props) => {
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('sales');
    const formatPrice = useFormatPrice();

    if (!record) return null;

    const items = [
        {
            label: getFieldLabel('totalVat'),
            value: formatPrice(record.totalVat),
        },
        {
            label: getFieldLabel('delivered'),
            value: <BooleanField source="delivered" />,
        },
    ];

    return <InfoSummary items={items} />;
};

SelloSaleAdditionalInfoField.defaultProps = {
    source: 'additionalInfo',
    sortable: false,
};

export default SelloSaleAdditionalInfoField;
