import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import SaleList from '@components/resource/sales/SaleList';

import { RegisterResource } from '@components/ResourceGuesser';
import { Sale } from '@js/interfaces/Sale';

// Sale represent abstract Sale @see App\Entity\Sale
export default {
    icon: MonetizationOnIcon,
    list: SaleList,
    show: null,
    create: null,
    edit: null,
} satisfies RegisterResource<Sale>;
