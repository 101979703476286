import { FC } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Box, BoxProps, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import RecordPriceField from '@components/field/RecordPriceField';

import { SelloOrderRow } from '@js/interfaces/selloOrderRow';
import { SelloSale } from '@js/interfaces/selloSale';

type Props = {
    source: string;
    currencyField: string;
    record?: SelloSale | SelloOrderRow;
    resource?: string;
    sortable?: boolean;
    addLabel?: boolean;
    label?: string;
    justifyContent?: BoxProps['justifyContent'];
};

const SelloSalesPriceField: FC<Props> = ({ source, currencyField, justifyContent, ...props }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    const isBundleSale = 'bundleSale' in record && record.bundleSale;
    const hasTooltip = !record.delivered || isBundleSale;

    const field = (
        <RecordPriceField
            source={source}
            currencyField={currencyField}
            color={!record.delivered ? 'disabled' : undefined}
            {...props}
        />
    );

    if (!hasTooltip) return field;

    return (
        <Box display="inline-flex" alignItems="center" gridGap={5} justifyContent={justifyContent}>
            {field}
            <Tooltip
                title={
                    <Box display="inline-flex" flexDirection="column">
                        {!record.delivered && <span>{translate('resources.sales.helper_text.not_delivered')}</span>}
                        {isBundleSale && <span>{translate('resources.sales.helper_text.bundle_sale')}</span>}
                    </Box>
                }
            >
                <InfoIcon fontSize="small" color="secondary" />
            </Tooltip>
        </Box>
    );
};

SelloSalesPriceField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default SelloSalesPriceField;
