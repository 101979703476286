import { HTMLAttributes, useEffect, useState } from 'react';
import { get } from 'lodash';
import {
    FieldTitle,
    InputHelperText,
    Record as RaRecord,
    useGetList,
    useInput,
    useResourceContext,
    Validator,
} from 'react-admin';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useResourceFieldName from '@js/hooks/useResourceFieldName';

type Props = {
    source: string;
    label?: string | false;
    record?: RaRecord;
    resource?: string;
    validate?: Validator | Validator[];
    fullWidth?: boolean;
    helperText?: string;
    queryField: string;
};

const useStyles = makeStyles((theme) => ({
    progressIcon: {
        position: 'absolute',
        top: 'calc(50% - 9px)',
        right: theme.spacing(8),
    },
}));

const TextAutocompleteInput = ({ label, fullWidth, queryField, helperText, ...props }: Props) => {
    const [open, setOpen] = useState(false);
    const {
        input,
        isRequired,
        meta: { touched, error, submitError },
    } = useInput(props);
    const classes = useStyles();

    const [debouncedInputValue, setDebouncedInputValue] = useState(input.value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedInputValue(input.value);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [input.value]);

    const resource = useResourceContext(props);
    const fieldName = useResourceFieldName(props);
    const { loading, data, ids } = useGetList(
        resource,
        { page: 1, perPage: 99 },
        { field: fieldName, order: 'ASC' },
        { [queryField]: debouncedInputValue },
        { enabled: !!debouncedInputValue },
    );
    // Get only unique suggestions
    const suggestions = Array.from(
        new Set(
            ids
                .map((id) => {
                    const value = get(data[id], queryField);
                    return typeof value === 'string' ? value : '';
                })
                .filter(Boolean),
        ),
        (title) => ({ title }),
    );

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={suggestions}
            loading={loading}
            onChange={(_e, newValue) => {
                input.onChange(newValue?.title ?? '');
            }}
            fullWidth={fullWidth}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={
                            label !== '' &&
                            label !== false && (
                                <FieldTitle
                                    label={label}
                                    source={props.source}
                                    resource={resource}
                                    isRequired={isRequired}
                                />
                            )
                        }
                        variant="filled"
                        margin="dense"
                        error={!!(touched && (error || submitError))}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading && (
                                        <CircularProgress color="inherit" className={classes.progressIcon} size={18} />
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        inputProps={{
                            ...params.inputProps,
                            value: input.value,
                            onChange: input.onChange,
                            onFocus: (event) => {
                                (
                                    params.inputProps as HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
                                )?.onFocus?.(event);
                                input.onFocus(event);
                            },
                            onBlur: (event) => {
                                (params.inputProps as HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>)?.onBlur?.(
                                    event,
                                );
                                input.onBlur(event);
                            },
                        }}
                        helperText={
                            <InputHelperText
                                touched={touched ?? false}
                                error={error || submitError}
                                helperText={helperText}
                            />
                        }
                    />
                );
            }}
        />
    );
};

export default TextAutocompleteInput;
