import { ComponentType, Dispatch, SetStateAction, useState } from 'react';
import { Button as RaButton, RecordContextProvider, useResourceContext, useTranslate } from 'react-admin';
import IconButton from '@components/button/IconButton';
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton as MuiIconButton,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { Field } from '@api-platform/api-doc-parser';

import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import GetAppIcon from '@material-ui/icons/GetApp';
import ContentSave from '@material-ui/icons/Save';

import CheckboxChunkGroupInput from '@components/input/CheckboxChunkGroupInput';
import LoadingButton from '@components/button/LoadingButton';
import InputGuesser from '@components/form/InputGuesser';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useFormFields from '@js/hooks/useFormFields';
import { post } from '@js/request/apiRequest';
import { useEntrypoint } from '@js/context/AppConfigContext';
import useOptionalControlledOpen, { UseOptionalControlledOpenProps } from '@js/hooks/useOptionalControlledOpen';

type CompletionField = string;

type Props = {
    onCompletion: (data: Record<CompletionField, any>) => Promise<void> | void;
    createGetCompletionPayload: (
        fields: CompletionField[],
    ) => { completionFields: CompletionField[] } & Record<string, any>;
    completionFields: CompletionField[];
    resource?: string;
    inputComponent?: ComponentType<{ source: string; field: Field; fullWidth?: boolean }>;
    disabledReason?: string | false;
} & UseOptionalControlledOpenProps;

const ChatGPTManagerButton = ({
    onCompletion,
    completionFields,
    createGetCompletionPayload,
    disabledReason,
    inputComponent = InputGuesser,
    ...props
}: Props) => {
    const [open, setOpen] = useOptionalControlledOpen(props);
    const resource = useResourceContext(props);

    const handleCompletion = async (data: Record<CompletionField, any>) => {
        await onCompletion(data);
        setOpen(false);
    };

    return (
        <>
            <IconButton
                label="app.label.chat_gpt_manager"
                onClick={() => setOpen(true)}
                disabledReason={disabledReason}
            >
                <OpenAIIcon />
            </IconButton>
            {open && (
                <ChatGPTManagerDialog
                    onClose={() => setOpen(false)}
                    createGetCompletionPayload={createGetCompletionPayload}
                    resource={resource}
                    inputComponent={inputComponent}
                    onCompletion={handleCompletion}
                    completionFields={completionFields}
                />
            )}
        </>
    );
};

const ChatGPTManagerDialog = ({
    onCompletion,
    completionFields,
    createGetCompletionPayload,
    onClose,
    resource,
    inputComponent,
}: { onClose: () => void } & Required<
    Pick<Props, 'onCompletion' | 'createGetCompletionPayload' | 'resource' | 'inputComponent' | 'completionFields'>
>) => {
    const [open, setOpen] = useState(true);
    const [completions, setCompletions] = useState<Record<CompletionField, any>>();
    const fullScreen = useIsSmallScreen();

    const handleClose = () => setOpen(false);

    return (
        <MuiDialog
            open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
            TransitionProps={{ onExited: onClose }}
        >
            <DialogTitle>
                <Box position="absolute" right={1} top={1}>
                    <MuiIconButton onClick={handleClose} disableRipple size="small">
                        <CloseIcon />
                    </MuiIconButton>
                </Box>
            </DialogTitle>
            {completions ? (
                <CompletionConfirmDialog
                    completions={completions}
                    onClose={handleClose}
                    inputComponent={inputComponent}
                    onCompletion={onCompletion}
                />
            ) : (
                <GetCompletionDialog
                    onCompletion={setCompletions}
                    onClose={handleClose}
                    resource={resource}
                    createGetCompletionPayload={createGetCompletionPayload}
                    completionFields={completionFields}
                />
            )}
        </MuiDialog>
    );
};

const CompletionConfirmDialog = ({
    completions,
    onClose,
    onCompletion,
    inputComponent: InputComponent,
}: {
    completions: Record<CompletionField, any>;
    onClose: () => void;
} & Required<Pick<Props, 'inputComponent' | 'onCompletion'>>) => {
    const completionFields = Object.keys(completions);
    const formFields = useFormFields();
    const fields = formFields.filter((field) => completionFields.includes(field.name));

    return (
        <RecordContextProvider value={completions}>
            <Form onSubmit={onCompletion} initialValues={completions}>
                {({ submitting, handleSubmit }) => (
                    <>
                        <DialogContent>
                            {fields.map((field) => {
                                return <InputComponent key={field.name} source={field.name} field={field} fullWidth />;
                            })}
                        </DialogContent>
                        <DialogActions>
                            <RaButton size="small" color="default" label="ra.action.cancel" onClick={onClose}>
                                <CancelIcon />
                            </RaButton>
                            <LoadingButton
                                loading={submitting}
                                onClick={handleSubmit}
                                label="ra.action.save"
                                icon={<ContentSave />}
                            />
                        </DialogActions>
                    </>
                )}
            </Form>
        </RecordContextProvider>
    );
};

const GetCompletionDialog = ({
    resource,
    onClose,
    onCompletion,
    createGetCompletionPayload,
    completionFields,
}: {
    resource: string;
    onClose: () => void;
    onCompletion: Dispatch<SetStateAction<Record<string, any>>>;
} & Required<Pick<Props, 'createGetCompletionPayload' | 'completionFields'>>) => {
    const getFieldLabel = useTranslateResourceField(resource);
    const translate = useTranslate();
    const entrypoint = useEntrypoint();
    const notifyError = useNotifyHttpError();

    const handleSubmit = async (values: { fields: CompletionField[] }) => {
        try {
            const response = await post<Record<CompletionField, any>>(`${entrypoint}/${resource}/get_completion`, {
                body: JSON.stringify(createGetCompletionPayload(values.fields)),
            });
            onCompletion(response);
        } catch (e) {
            notifyError(e);
        }
    };

    return (
        <Form onSubmit={handleSubmit} initialValues={{ fields: completionFields }}>
            {({ submitting, handleSubmit, values }) => (
                <>
                    <DialogContent>
                        <CheckboxChunkGroupInput
                            label={translate('app.message.select_fields')}
                            chunkSize={2}
                            source="fields"
                            choices={completionFields.map((field) => ({ id: field, name: getFieldLabel(field) }))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <RaButton size="small" color="default" label="ra.action.cancel" onClick={onClose}>
                            <CancelIcon />
                        </RaButton>
                        <LoadingButton
                            disabled={values.fields.length === 0}
                            loading={submitting}
                            onClick={handleSubmit}
                            label="app.action.get_completion"
                            icon={<GetAppIcon />}
                        />
                    </DialogActions>
                </>
            )}
        </Form>
    );
};

const useIconStyles = makeStyles({
    root: {
        fill: 'none',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
});

const OpenAIIcon = () => {
    const classes = useIconStyles();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classes.root}>
            <path
                d="M11.7453 14.85L6.90436 12V7C6.90436 4.79086 8.72949 3 10.9809 3C12.3782 3 13.6113 3.6898 14.3458 4.74128"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.59961 19.1791C10.3266 20.2757 11.5866 21.0008 13.0192 21.0008C15.2707 21.0008 17.0958 19.21 17.0958 17.0008V12.0008L12.1612 9.0957"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.45166 13.5L9.45123 7.66938L13.8642 5.16938C15.814 4.06481 18.3072 4.72031 19.4329 6.63348C20.1593 7.86806 20.1388 9.32466 19.5089 10.4995"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.48963 13.4993C3.8595 14.6742 3.83887 16.131 4.56539 17.3657C5.6911 19.2789 8.18428 19.9344 10.1341 18.8298L14.5471 16.3298L14.643 10.7344"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.0959 17.6309C18.4415 17.5734 19.7295 16.8634 20.4529 15.634C21.5786 13.7209 20.9106 11.2745 18.9608 10.1699L14.5478 7.66992L9.48907 10.4255"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.90454 6.36938C5.55865 6.42662 4.27032 7.13672 3.54684 8.3663C2.42113 10.2795 3.08917 12.7258 5.03896 13.8304L9.45196 16.3304L14.5 13.5807"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ChatGPTManagerButton;
