import { Datagrid, DateField, List, ListProps, useGetResourceLabel } from 'react-admin';

import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';

import ExpandRow, { isRowExpandable } from './ExpandRow';
import { rowStyle } from './SalesReferenceDatagrid';
import StatusField from './field/StatusField';
import TypeField from './field/TypeField';
import QuantityField from './field/QuantityField';
import SalesPriceField from './field/SalesPriceField';
import AdditionalInfoField from './field/AdditionalInfoField';
import ReclamationReferenceField from './field/ReclamationReferenceField';
import RowActions from './RowActions';

import useListFilters from './useListFlters';

const SaleList = (props: ListProps) => {
    const filters = useListFilters();
    const getResourceLabel = useGetResourceLabel();

    return (
        <List {...props} exporter={false} filters={filters} sort={{ field: 'soldAt', order: 'DESC' }}>
            <Datagrid isRowExpandable={isRowExpandable} expand={<ExpandRow />} rowStyle={rowStyle}>
                <DateField source="soldAt" showTime />
                <TypeField />
                <StatusField />
                <QuantityField />
                <SalesPriceField />
                <ReferenceFieldGuesser source="saleChannel" sortable={false} asText />
                <ReclamationReferenceField label={getResourceLabel('reclamations', 1)} />
                <AdditionalInfoField />
                <RowActions />
            </Datagrid>
        </List>
    );
};

export default SaleList;
