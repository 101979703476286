import {
    ReferenceArrayField,
    Show,
    ShowProps,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TabbedShowLayoutProps,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';

import RecordTitle from '@components/detail/RecordTitle';
import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';
import FileField from '@components/field/FileField';
import TabbedTabGuesser from '@components/detail/TabbedTabGuesser';
import SalesReferenceDatagrid from '@components/resource/sales/SalesReferenceDatagrid';

import QuantityField from './field/QuantityField';

import { Product } from '@js/interfaces/product';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props extends ShowProps {
    record?: Product;
}

const TabbedShowLayoutWrapper = (props: Omit<TabbedShowLayoutProps, 'children'>) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const translateField = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();

    const isBundle = record?.splitType === 'bundle';
    const target = isBundle ? 'product.bundle' : 'product';

    return (
        <TabbedShowLayout {...props}>
            <TabbedTabGuesser label={translate('resources.products.tab.general')}>
                <QuantityField source="quantity" />
                <ReferenceArrayField source="images" reference="reclamation_media_objects">
                    <SingleFieldList linkType={false}>
                        <FileField />
                    </SingleFieldList>
                </ReferenceArrayField>
            </TabbedTabGuesser>
            {isBundle && (
                <Tab label={translateField('bundleItems')} path="bundle">
                    <ReferenceFieldGuesser source="bundleItems" />
                </Tab>
            )}
            <Tab label={getResourceLabel('sales')} path="sales">
                <SalesReferenceDatagrid target={target} />
            </Tab>
        </TabbedShowLayout>
    );
};

const ProductShow = (props: Props) => {
    return (
        <Show title={<RecordTitle />} {...props}>
            <TabbedShowLayoutWrapper />
        </Show>
    );
};

export default ProductShow;
