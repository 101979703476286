import { useCallback, useEffect, useRef, useState } from 'react';
import { useVersion } from 'react-admin';

import { get } from '@js/request/apiRequest';
import useDidMountEffect from '@js/hooks/useDidMountEffect';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import { useEntrypoint, useSettings } from '@js/context/AppConfigContext';

type DeployVersionPayload = {
    deployVersion: number;
};

/**
 * Checks if the application code has changed
 *
 * It checks for code update by downloading the HTML page and
 * comparing the hash of the response with the hash of the current page.
 */
export const useCheckForApplicationUpdate = () => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
    const version = useVersion();
    const subscribe = useCustomMercureSubscription();

    const settings = useSettings();
    const currentDeployVersion = useRef(settings.deployVersion);
    const entrypoint = useEntrypoint();

    // 30 minutes
    const delay = 1000 * 60 * 30;
    const disabled = process.env.NODE_ENV !== 'production';

    const checkForNewVersion = useCallback(() => {
        get<DeployVersionPayload>(`${entrypoint}/settings/deploy_version`)
            .then(({ deployVersion }) => {
                if (deployVersion !== currentDeployVersion.current) {
                    setIsNewVersionAvailable(true);
                    currentDeployVersion.current = deployVersion;
                }
            })
            .catch(() => {
                // Ignore errors to avoid issues when connectivity is lost
            });
    }, [entrypoint]);

    // Subscribe for new versions
    useEffect(() => {
        return subscribe('/api/app_update_deploy', (data) => {
            const deployVersion = (JSON.parse(data) as DeployVersionPayload).deployVersion;

            setIsNewVersionAvailable(true);
            currentDeployVersion.current = deployVersion;
        });
    }, [subscribe]);

    // Check for a new version on refresh
    useDidMountEffect(() => {
        if (disabled) return;

        checkForNewVersion();
    }, [version, checkForNewVersion, disabled]);

    // Check for a new version using an interval
    useEffect(() => {
        if (disabled) return;

        const interval = setInterval(checkForNewVersion, delay);
        return () => clearInterval(interval);
    }, [delay, disabled, checkForNewVersion]);

    return isNewVersionAvailable;
};
