import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const StatusField = (props: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    return (
        <Typography component="span" variant="body2">
            {translate(`resources.sales.status.${record.status}`)}
        </Typography>
    );
};

StatusField.defaultProps = {
    source: 'status',
    sortable: false,
};

export default StatusField;
