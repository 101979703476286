import { useRecordContext } from 'react-admin';

import SelloSaleRowActions from './SelloSaleRowActions';
import ExternalSaleRowActions from './ExternalSaleRowActions';
import ManualSalesRowActions from './ManualSalesRowActions';
import PurchaseSeparatelySaleRowActions from './PurchaseSeparatelySaleRowActions';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const RowActions = (props: Props) => {
    const record = useRecordContext(props);

    if (!record) return null;

    switch (record.type) {
        case 'sello':
            return <SelloSaleRowActions {...props} record={record} />;
        case 'external':
            return <ExternalSaleRowActions {...props} record={record} />;
        case 'manual':
            return <ManualSalesRowActions {...props} record={record} />;
        case 'purchase_separately':
            return <PurchaseSeparatelySaleRowActions {...props} record={record} />;
        default:
            return null;
    }
};

export default RowActions;
