import { PurchaseSeparatelySale } from '@js/interfaces/PurchaseSeparatelySale';
import { useRecordContext } from 'react-admin';
import { Box } from '@material-ui/core';
import DeleteIconButton from '@components/button/DeleteIconButton';

const PurchaseSeparatelySaleRowActions = (props: { record?: PurchaseSeparatelySale; resource?: string }) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <Box display="flex" justifyContent="end">
            <DeleteIconButton
                {...props}
                disabledReason={
                    record.canDelete
                        ? undefined
                        : 'resources.sales.delete_disable_reason.purchased_separately_child_sold'
                }
            />
        </Box>
    );
};

export default PurchaseSeparatelySaleRowActions;
