import { useEffect, useState } from 'react';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useNotify, useResourceContext, useTranslate } from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
    DialogTitle,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import LoadingButton from '@components/button/LoadingButton';
import DialogErrorContent from './DialogErrorContent';

import useFormatTimeElapsed from '@js/hooks/useFormatTimeElapsed';
import { useParseHttpErrorAsString } from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';
import useSecondsTimer from '@components/form/LockedFormSafeguard/useSecondsTimer';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import useGetLockRecord from '@js/hooks/useGetLockRecord';
import useLockRecord from '@js/hooks/useLockRecord';

import { LockRecord } from '@js/interfaces/lockrecord';
import { Iri } from '@js/interfaces/ApiRecord';
import useGetIdentity from '@js/hooks/useGetIdentity';

type ShowDialog = 'request_take_over' | 'wait_hand_over' | 'force_take_over' | 'error';

type TakeOverEditingProps = {
    id: Iri;
    resource?: string;
    onCancel?: () => void;
};

export type RequestBody = {
    record: Iri;
    action: 'request' | 'force';
};

const useTakeOverEditingStyles = makeStyles<Theme, { preview?: boolean }>({
    scrollPaper: {
        alignItems: (props) => (props.preview ? 'start' : 'center'),
    },
});

const TakeOverEditing = ({ id, onCancel, ...props }: TakeOverEditingProps) => {
    const [error, setError] = useState('');
    const [dialog, setDialog] = useState<ShowDialog>('request_take_over');
    const resource = useResourceContext(props);
    const history = useHistory();
    const parseError = useParseHttpErrorAsString();
    const notify = useNotify();

    const [preview, setPreview] = useState(false);
    const classes = useTakeOverEditingStyles({ preview });

    const [wasTookOver, setWasTookOver] = useState(false);
    const lock = useGetLockRecord(id);
    const lockFetched = lock !== undefined;
    const canEditRecord = wasTookOver || (lockFetched && !lock?.isValid());

    // Lock record for current user if was taken over or lock is not valid
    useLockRecord(canEditRecord ? id : undefined);

    const isSmallScreen = useIsSmallScreen();
    const translate = useTranslate();

    const handleClose = onCancel || (() => history.push(`/${resource}`));
    const handleError = async (error: unknown) => {
        setError(await parseError(error));
        setDialog('error');
    };

    const handleHandOver = () => {
        setWasTookOver(true);
        notify('app.message.took_over_editing', { type: 'info', messageArgs: { username: lock?.username } });
    };

    if (!lock) return null;

    return (
        <Dialog
            open={!wasTookOver && lock.isValid()}
            fullScreen={isSmallScreen}
            maxWidth="md"
            classes={{ scrollPaper: classes.scrollPaper }}
            fullWidth
            disableScrollLock
        >
            <DialogTitle>{translate('app.action.take_over')}</DialogTitle>
            {dialog === 'request_take_over' && (
                <DialogRequestTakeOver
                    lock={lock}
                    onTakeOverRequest={() => setDialog('wait_hand_over')}
                    onClose={handleClose}
                    onError={handleError}
                    onPreview={() => setPreview(true)}
                />
            )}
            {dialog === 'wait_hand_over' && (
                <DialogWaitHandOver
                    lock={lock}
                    onExpired={() => setDialog('force_take_over')}
                    onHandOver={handleHandOver}
                    onClose={handleClose}
                    onError={handleError}
                />
            )}
            {dialog === 'force_take_over' && (
                <DialogForceTakeOver
                    onForceTakeOver={handleHandOver}
                    onClose={handleClose}
                    onError={handleError}
                    lock={lock}
                />
            )}
            {dialog === 'error' && <DialogErrorContent error={error} onClose={handleClose} />}
        </Dialog>
    );
};

interface CommonDialogProps {
    onClose: () => void;
    onError: (error: unknown) => void;
    lock: LockRecord;
}

const DialogRequestTakeOver = ({
    onClose,
    onError,
    lock,
    onTakeOverRequest,
    onPreview,
}: CommonDialogProps & { onTakeOverRequest: () => void; onPreview: () => void }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const formatAgo = useFormatTimeElapsed();

    const handleTakeOverRequestClick = () => {
        setLoading(true);

        const body: RequestBody = { record: lock.iri, action: 'request' };
        const receiver = lock.user;

        // Send take over request to owner of lock
        post(`${receiver}/take_over_editing`, { body: JSON.stringify(body) })
            .then(onTakeOverRequest)
            .catch(onError);
    };

    return (
        <>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {translate('app.message.record_locked', {
                        username: lock.username,
                        time: formatAgo(lock.createdAt),
                    })}
                </Typography>
                <Typography gutterBottom>
                    {translate('app.message.request_take_over_editing', { username: lock.username })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
                <Button onClick={onPreview} color="primary">
                    {translate('app.action.preview')}
                </Button>
                <Button
                    onClick={handleTakeOverRequestClick}
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={loading}
                >
                    {translate('app.action.take_over')}
                </Button>
            </DialogActions>
        </>
    );
};

interface DialogWaitHandOverProps extends CommonDialogProps {
    onExpired: () => void;
    onHandOver: () => void;
}

type HandOverMessage = {
    action: 'accept' | 'reject';
    sender: Iri;
};

const DialogWaitHandOver = ({ onClose, onExpired, lock, onHandOver, onError }: DialogWaitHandOverProps) => {
    const { seconds, expired } = useSecondsTimer(60);
    const translate = useTranslate();
    const subscription = useCustomMercureSubscription();
    const { identity } = useGetIdentity();

    useEffect(() => {
        if (expired) {
            onExpired();
        }
    }, [expired, onExpired]);

    useEffect(() => {
        if (!identity?.id) return;

        return subscription(`${identity.id}/hand_over_editing?topic=${encodeURIComponent(lock.iri)}`, (message) => {
            const payload = JSON.parse(message) as HandOverMessage;
            if (payload.action === 'accept') {
                onHandOver();
            } else {
                onError(translate('app.message.hand_over_rejected', { username: lock.username }));
            }
        });
    }, [identity?.id, lock.iri, lock.username, onClose, onError, onHandOver, subscription, translate]);

    return (
        <>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {translate('app.message.waiting_handle_over', {
                        username: lock.username,
                        time: seconds,
                    })}
                </Typography>
                <LinearProgress />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={!expired}>
                    {translate('ra.action.cancel')}
                </Button>
            </DialogActions>
        </>
    );
};

interface DialogForceTakeOverProps extends CommonDialogProps {
    onForceTakeOver: () => void;
}

const DialogForceTakeOver = ({ onClose, onForceTakeOver, onError, lock }: DialogForceTakeOverProps) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);

    const handleForceTakeOverClick = () => {
        setLoading(true);

        const body: RequestBody = { record: lock.iri, action: 'force' };
        const receiver = lock.user;

        post(`${receiver}/take_over_editing`, { body: JSON.stringify(body) })
            .then(onForceTakeOver)
            .catch(onError);
    };

    return (
        <>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {translate('app.message.force_take_over', { username: lock.username })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
                <LoadingButton
                    icon={<PriorityHighIcon />}
                    label="app.action.force_take_over"
                    loading={loading}
                    onClick={handleForceTakeOverClick}
                />
            </DialogActions>
        </>
    );
};

export default TakeOverEditing;
