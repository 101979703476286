import { useCallback } from 'react';
import { useTranslate } from 'react-admin';

export const Intervals = {
    year: { label: 'app.label.year', seconds: 31536000 },
    month: { label: 'app.label.month', seconds: 2592000 },
    week: { label: 'app.label.week', seconds: 604800 },
    day: { label: 'app.label.day', seconds: 86400 },
    hour: { label: 'app.label.hour', seconds: 3600 },
    minute: { label: 'app.label.minute', seconds: 60 },
    second: { label: 'app.label.second', seconds: 1 },
} as const;

export type Interval = keyof typeof Intervals;

export type Options = { excludeIntervals?: Interval[]; excludeAgo?: boolean };

const useFormatSecondsElapsed = (options: Options = {}) => {
    const translate = useTranslate();

    return useCallback(
        (seconds: number) => {
            let diffInSeconds = seconds;
            const diff: string[] = [];

            for (const [interval, intervalEntry] of Object.entries(Intervals)) {
                if (options.excludeIntervals?.includes(interval as Interval) && diff.length > 0) continue;

                const count = Math.floor(diffInSeconds / intervalEntry.seconds);

                if (count > 0) {
                    diff.push(`${count} ${translate(intervalEntry.label, { smart_count: count }).toLowerCase()}`);
                    diffInSeconds -= count * intervalEntry.seconds;
                }
            }

            let diffString =
                diff.join(', ') || `0 ${translate(Intervals.second.label, { smart_count: 2 }).toLowerCase()}`;

            if (!options.excludeAgo) {
                diffString += ` ${translate('app.label.ago')}`;
            }

            return diffString;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(options), translate],
    );
};

export default useFormatSecondsElapsed;
