import { useRecordContext } from 'react-admin';
import { Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import IconButton from '@components/button/IconButton';
import ResyncSelloOrderButton from './ResyncSelloOrderButton';

import { createSelloProductSearchUrl } from '@js/utility/sello';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

type Props = { record?: SelloSale | SelloOrderRow; addLabel?: boolean; sortable?: boolean };

const SelloSaleRowActions = (props: Props) => {
    const record = useRecordContext(props);

    if (!record) return null;

    const handleOpenSelloClick = () => {
        const link = createSelloProductSearchUrl(record.productId);
        window.open(link, '_blank', 'noreferrer');
    };

    return (
        <Box display="flex" justifyContent="end">
            <IconButton label="app.action.open_in_sello" onClick={handleOpenSelloClick}>
                <VisibilityIcon />
            </IconButton>
            <ResyncSelloOrderButton record={record} />
        </Box>
    );
};

SelloSaleRowActions.defaultProps = {
    addLabel: false,
    sortable: false,
};

export default SelloSaleRowActions;
