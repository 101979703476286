import { useVersion } from 'react-admin';
import { useCallback, useEffect, useState } from 'react';

import { get, GetParams } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

type Options = {
    enabled?: boolean;
};

type ReturnType<T> =
    | { loading: boolean; loaded: false; error: unknown; data: undefined; refresh: () => void }
    | { loading: boolean; loaded: true; error: unknown; data: T; refresh: () => void };

const useFetchGet = <T>(url: string | URL, params: GetParams = {}, options: Options = {}): ReturnType<T> => {
    const { enabled = true } = options;
    const version = useVersion();
    const [refresh, setRefresh] = useState(0);

    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(enabled);
    const [error, setError] = useState<unknown>();
    const notifyFailure = useNotifyHttpError();

    useEffect(() => {
        if (!enabled) {
            return;
        }

        setLoading(true);
        const abortController = new AbortController();

        get<T>(url, params, { signal: abortController.signal })
            .then((response) => {
                setData(response);
                setLoading(false);
            })
            .catch((error) => {
                if (abortController.signal.aborted) {
                    return;
                }

                notifyFailure(error);
                setError(error);
                setData(undefined);
                setLoading(false);
            });

        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url.toString(), JSON.stringify(params), enabled, notifyFailure, version, refresh]);

    const doRefresh = useCallback(() => setRefresh((refresh) => refresh + 1), []);

    return { data, loading, loaded: !!data, error, refresh: doRefresh } as ReturnType<T>;
};

export default useFetchGet;
