import { ReferenceField } from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import FunctionField from '@components/field/FunctionField';
import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';

import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { Reclamation } from '@js/interfaces/reclamation';
import { Sale } from '@js/interfaces/Sale';

type Props = {
    label: string;
    record?: Sale;
    resource?: string;
};

const ReclamationReferenceField = (props: Props) => {
    const fieldName = useResourceFieldName('reclamations');

    return (
        <ReferenceField {...props} source="reclamation" reference="reclamations" sortable={false} link={false}>
            <FunctionField<Reclamation>
                render={(record) => {
                    const label = get(record, fieldName);
                    return (
                        <ClickToCopyTooltip text={record.composedIdentifier}>
                            <Typography component="span" variant="body2">
                                {`${label} (${record.composedIdentifier})`}
                            </Typography>
                        </ClickToCopyTooltip>
                    );
                }}
            />
        </ReferenceField>
    );
};

export default ReclamationReferenceField;
