import { useRecordContext } from 'react-admin';

import SelloSaleAdditionalInfoField from './SelloSaleAdditionalInfoField';
import GiabsSaleAdditionalInfoField from './GiabsSaleAdditionalInfoField';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const AdditionalInfoField = (props: Props) => {
    const record = useRecordContext(props);

    if (!record) return null;

    switch (record.type) {
        case 'sello':
            return <SelloSaleAdditionalInfoField {...props} record={record} />;
        case 'giab_s':
            return <GiabsSaleAdditionalInfoField {...props} record={record} />;
        default:
            return null;
    }
};

AdditionalInfoField.defaultProps = {
    source: 'additionalInfo',
    sortable: false,
};

export default AdditionalInfoField;
