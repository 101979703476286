export const getTimestamp = (): number => Date.now() / 1000;

export const toLocaleDateString = (date: string): string => {
    try {
        return new Date(date).toLocaleDateString();
    } catch (error) {
        console.error(error);

        return 'Invalid date';
    }
};

export const toLocaleString = (date: string | undefined): string => {
    if (!date) {
        return '[Not defined]';
    }

    try {
        return new Date(date).toLocaleString();
    } catch (error) {
        console.error(error);

        return '[Invalid date]';
    }
};
