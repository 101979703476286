import { Record as RaRecord, useRecordContext } from 'react-admin';

import SelloSaleExpandRow from './SelloSaleExpandRow';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    record?: Sale;
    resource?: string;
};

const ExpandRow = (props: Props) => {
    const record = useRecordContext(props);

    if (!record) return null;

    switch (record.type) {
        case 'sello':
            return <SelloSaleExpandRow {...props} record={record} />;
        default:
            return null;
    }
};

export const isRowExpandable = (record?: Sale | RaRecord) => {
    return record?.type === 'sello';
};

export default ExpandRow;
