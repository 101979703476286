import { useState } from 'react';
import {
    minValue,
    NumberInput,
    required,
    useListContext,
    useNotify,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';

import IconButton from '@components/button/IconButton';
import DeleteIconButton from '@components/button/DeleteIconButton';
import UpdateDialogForm from '@components/form/UpdateDialogForm';
import CurrencySelectInput from '@components/input/CurrencySelectInput';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import decimal from '@js/validator/decimal';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

import { ExternalSale } from '@js/interfaces/externalSale';

type Props = { addLabel?: boolean; sortable?: boolean; record?: ExternalSale; resource?: string };

const ExternalSaleRowActions = (props: Props) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const { refetch } = useListContext();
    const notify = useNotify();
    const getFieldLabel = useTranslateResourceField();

    if (!record) return null;

    const isBundleSale = record?.bundle;
    let disabledReason;

    if (isBundleSale) {
        disabledReason = 'resources.sales.edit_disable_reason.edit_bundle_item_sale_prohibited';
    }

    return (
        <>
            <Box display="flex" justifyContent="end">
                <IconButton label="ra.action.edit" onClick={() => setOpen(true)} disabledReason={disabledReason}>
                    <EditIcon />
                </IconButton>
                <DeleteIconButton
                    disabledReason={disabledReason}
                    onSuccess={() => {
                        notify('ra.notification.deleted', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                            undoable: false,
                        });
                        refetch();
                    }}
                />
            </Box>
            {!isBundleSale && (
                <UpdateDialogForm
                    title="ra.action.edit"
                    record={record}
                    resource={resource}
                    open={open}
                    onClose={() => setOpen(false)}
                    mutationMode="pessimistic"
                    initialValues={{
                        salesPricePerItem: record.salesPricePerItem,
                        saleChannel: record.saleChannel,
                        quantity: record.quantity,
                        currency: record.currency,
                    }}
                >
                    <NumberInput
                        source="salesPricePerItem"
                        label={getFieldLabel('salesPrice')}
                        step="0.01"
                        validate={[decimal(), required(), minValue(1)]}
                        helperText={translate('app.form.helperText.price_per_item_input')}
                        fullWidth
                    />
                    <CurrencySelectInput source="currency" resource={resource} validate={required()} fullWidth />
                    <NumberInput
                        source="quantity"
                        resource={resource}
                        step="1"
                        validate={[required(), minValue(1)]}
                        fullWidth
                    />
                    <AutoCompleteGuesserInput
                        source="saleChannel"
                        resource={resource}
                        validate={required()}
                        fullWidth
                    />
                </UpdateDialogForm>
            )}
        </>
    );
};

ExternalSaleRowActions.defaultProps = {
    addLabel: false,
    sortable: false,
};

export default ExternalSaleRowActions;
