import {
    Datagrid,
    DateField,
    Identifier,
    LinearProgress,
    Record as RaRecord,
    ReferenceManyField,
    Tab,
    TabProps,
    TextField,
    useGetResourceLabel,
    useReference,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import { rowStyle as salesRowStyle } from '@components/resource/sales/SalesReferenceDatagrid';
import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';
import AutoHidePagination from '@components/list/AutoHidePagination';
import FunctionField from '@components/field/FunctionField';
import SalesPriceField from '@components/resource/sales/field/SalesPriceField';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import StatusField from '@components/resource/sales/field/StatusField';
import TypeField from '@components/resource/sales/field/TypeField';

import QuantityField from './field/QuantityField';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Product } from '@js/interfaces/product';
import { Reclamation } from '@js/interfaces/reclamation';

interface Props extends Omit<TabProps, 'children' | 'record'> {
    record?: Product;
}

const BundleItemsTab = (props: Props) => (
    <Tab {...props}>
        <TabContent />
    </Tab>
);

const TabContent = (props: { record?: Product; resource?: string }) => {
    const getFieldLabel = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box my={2}>
            <ReferenceManyField reference="products" target="bundle" pagination={<AutoHidePagination />} {...props}>
                <Datagrid expand={Expand}>
                    <TextField source="title" label={getFieldLabel('title', 'reclamations')} />
                    <QuantityField source="quantity" label={getFieldLabel('quantity', 'reclamations')} />
                    <TextField
                        source="composedIdentifier"
                        label={getFieldLabel('composedIdentifier', 'reclamations')}
                    />
                    <FunctionField<Product>
                        label={getResourceLabel('reclamations', 1)}
                        render={(record) => {
                            if (!record?.reclamation) return null;
                            return <ReclamationField reclamationId={record.reclamation} />;
                        }}
                    />
                </Datagrid>
            </ReferenceManyField>
        </Box>
    );
};

const useExpandStyles = makeStyles({
    root: {
        '& .MuiTableCell-head:last-child': {
            textAlign: 'right',
        },
        '& .MuiTableCell-body:last-child': {
            textAlign: 'right',
        },
    },
});

const Expand = (props: { record?: RaRecord; resource?: string }) => {
    const classes = useExpandStyles();

    return (
        <div className={classes.root}>
            <ReferenceManyField
                {...props}
                reference="sales"
                target="product"
                sort={{ field: 'soldAt', order: 'desc' }}
                pagination={<AutoHidePagination />}
                perPage={5}
            >
                <Datagrid {...props} empty={<AlertEmptyResource mb={2} />} rowStyle={salesRowStyle}>
                    <DateField source="soldAt" showTime />
                    <StatusField />
                    <TypeField />
                    <SalesPriceField />
                </Datagrid>
            </ReferenceManyField>
        </div>
    );
};

const ReclamationField = ({ reclamationId }: { reclamationId: Identifier }) => {
    const { referenceRecord } = useReference({ reference: 'reclamations', id: reclamationId.toString() });

    if (!referenceRecord) return <LinearProgress />;
    const reclamation = referenceRecord as Reclamation;

    return (
        <ClickToCopyTooltip text={reclamation.composedIdentifier}>{reclamation.composedIdentifier}</ClickToCopyTooltip>
    );
};

export default BundleItemsTab;
