import { FormControlLabel, Switch } from '@material-ui/core';
import { LinearProgress, useInput, useTranslate } from 'react-admin';

import { useZebraPrinterContext, ZebraPrinterContextValue } from '@js/context/ZebraPrinterContext';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';

const PrintLabelInput = (props: { source: string }) => {
    const { loaded, errors } = useZebraPrinterContext();

    if (!loaded) {
        return <LinearProgress />;
    }

    return <PrintLabelCheckbox errors={errors} {...props} />;
};

const PrintLabelCheckbox = ({
    errors,
    ...props
}: Pick<ZebraPrinterContextValue, 'errors'> & {
    source: string;
}) => {
    const hasErrors = !!errors;

    const features = useResourceFeatures('reclamations');
    const { input } = useInput({
        ...props,
        initialValue: !(hasErrors || features.disableDefaultLabelPrint),
        type: 'checkbox',
    });
    const translate = useTranslate();

    return (
        <>
            <FormControlLabel
                disabled={hasErrors}
                control={<Switch {...input} color="primary" />}
                label={
                    <>
                        {translate('app.action.print_label')}
                        {errors && <span> ({errors})</span>}
                    </>
                }
            />
            <PrintLabelsQuantitySameAsQuantity disabled={!input.checked || hasErrors} />
        </>
    );
};

const PrintLabelsQuantitySameAsQuantity = ({ disabled }: { disabled: boolean }) => {
    const { input } = useInput({
        source: 'printLabelsQuantitySameAsQuantity',
        type: 'checkbox',
    });

    const translate = useTranslate();

    return (
        <FormControlLabel
            disabled={disabled}
            control={<Switch {...input} color="primary" />}
            label={translate('app.label.print_same_number_as_quantity')}
        />
    );
};

export default PrintLabelInput;
