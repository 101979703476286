import { ManualSale } from '@js/interfaces/ManualSale';
import { useState } from 'react';
import {
    DateTimeInput,
    maxValue,
    minValue,
    NumberInput,
    required,
    useGetOne,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';

import IconButton from '@components/button/IconButton';
import DeleteIconButton from '@components/button/DeleteIconButton';
import UpdateDialogForm from '@components/form/UpdateDialogForm';
import CurrencySelectInput from '@components/input/CurrencySelectInput';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import decimal from '@js/validator/decimal';

import { Reclamation } from '@js/interfaces/reclamation';

const ManualSalesRowActions = (props: { record?: ManualSale; resource?: string }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const getFieldLabel = useTranslateResourceField();
    const translate = useTranslate();
    const { data: reclamation } = useGetOne<Reclamation>('reclamations', record?.reclamation ?? 0);

    if (!record || !reclamation) return null;

    // Because we are editing existing sale, it's quantity is already subtracted from the stock,
    // add it back to know the stock without this sale
    const inStock = (reclamation.inStockQuantity ?? 0) + record.quantity;

    return (
        <>
            <Box display="flex" justifyContent="end">
                <IconButton label="ra.action.edit" onClick={() => setOpen(true)}>
                    <EditIcon />
                </IconButton>
                <DeleteIconButton {...props} />
            </Box>
            <UpdateDialogForm<ManualSale>
                title="ra.action.edit"
                record={record}
                resource={resource}
                open={open}
                onClose={() => setOpen(false)}
                mutationMode="optimistic"
                initialValues={{
                    salesPricePerItem: record.salesPricePerItem,
                    saleChannel: record.saleChannel,
                    quantity: record.quantity,
                    soldAt: record.soldAt,
                    currency: record.currency,
                }}
            >
                <NumberInput
                    label={getFieldLabel('salesPrice')}
                    source="salesPricePerItem"
                    step="0.01"
                    validate={[decimal(), required(), minValue(1)]}
                    fullWidth
                    resource={resource}
                    helperText={translate('app.form.helperText.price_per_item_input')}
                />
                <CurrencySelectInput source="currency" validate={required()} resource={resource} fullWidth />
                <NumberInput
                    source="quantity"
                    step="1"
                    validate={[required(), minValue(1), maxValue(inStock)]}
                    fullWidth
                    resource={resource}
                    helperText={translate('app.label.max_value', { value: inStock })}
                />
                <AutoCompleteGuesserInput source="saleChannel" validate={required()} fullWidth resource={resource} />
                <DateTimeInput source="soldAt" validate={required()} fullWidth resource={resource} />
            </UpdateDialogForm>
        </>
    );
};

export default ManualSalesRowActions;
