import { useCallback } from 'react';
import useFormatSecondsElapsed, { Options } from '@js/hooks/useFormatSecondsElapsed';

const useFormatTimeElapsed = (options: Options = {}) => {
    const formatSecondsElapsed = useFormatSecondsElapsed(options);

    return useCallback(
        (from?: string | Date, to: string | Date = new Date()) => {
            if (!from) return '[Invalid ate]';

            if (typeof from === 'string') {
                from = new Date(from);
            }
            if (typeof to === 'string') {
                to = new Date(to);
            }

            return formatSecondsElapsed(Math.floor((to.getTime() - from.getTime()) / 1000));
        },
        [formatSecondsElapsed],
    );
};

export default useFormatTimeElapsed;
