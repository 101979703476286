import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const TypeField = (props: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    return (
        <Typography component="span" variant="body2">
            {translate(`resources.sales.type.${record.type}`)}
        </Typography>
    );
};

TypeField.defaultProps = {
    source: 'type',
    sortable: false,
};

export default TypeField;
