import { ReactNode } from 'react';
import { BooleanField, useLocale, useRecordContext } from 'react-admin';

import BooleanTooltipField from '@components/field/BooleanTooltipField';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

type Props = {
    record?: SelloSale | SelloOrderRow;
    resource?: string;
};

const SelloSaleExpandRow = (props: Props) => {
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('sales');
    const locale = useLocale();

    if (!record) return null;

    return (
        <table>
            <tbody>
                <Row label={getFieldLabel('selloOrderId')}>{record.selloOrderId}</Row>
                <Row label={getFieldLabel('selloRowId')}>
                    {'selloRowId' in record ? record.selloRowId : record.selloId}
                </Row>
                <Row label={getFieldLabel('customerEmail')}>{record.customerEmail}</Row>
                <Row label={getFieldLabel('customerName')}>{record.customerName}</Row>
                <Row label={getFieldLabel('paid')}>
                    <BooleanField source="paid" looseValue />
                </Row>
                <Row label={getFieldLabel('delivered')}>
                    <BooleanField source="delivered" looseValue />
                </Row>
                <Row label={getFieldLabel('canceledAt')}>
                    <BooleanTooltipField<SelloSale | SelloOrderRow>
                        source="canceledAt"
                        valueLabelTrue={(r) =>
                            r.canceledAt ? new Date(r.canceledAt).toLocaleString(locale) : undefined
                        }
                    />
                </Row>
                <Row label={getFieldLabel('orderNotes')}>{record.orderNotes}</Row>
            </tbody>
        </table>
    );
};

const Row = ({ label, children }: { label: string; children: ReactNode }) => {
    return (
        <tr>
            <th>{label}:</th>
            <td>{children}</td>
        </tr>
    );
};

export default SelloSaleExpandRow;
