import { FC } from 'react';
import { Identifier, Tab, TabbedShowLayout, useGetResourceLabel, useRecordContext } from 'react-admin';

import SalesReferenceDatagrid from '@components/resource/sales/SalesReferenceDatagrid';
import Logs from '@components/resource/errand/ErrandList/ExpandPanel/Logs';

import BundleItemsTab from './BundleItemsTab';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

import { Product } from '@js/interfaces/product';

const ProductExpand: FC<{
    id?: Identifier;
    record?: Product;
    resource?: string;
}> = (props) => {
    const record = useRecordContext(props);
    const translateField = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();

    const isLogsEnabled = permissions.isResourceEnabled('log_entries');
    const isBundle = record?.splitType === 'bundle';
    // Search in bundle items for sales if product is a bundle, otherwise search in product
    const target = isBundle ? 'product.bundle' : 'product';

    return (
        <TabbedShowLayout syncWithLocation={false} record={record} resource={props.resource}>
            {isBundle && <BundleItemsTab label={translateField('bundleItems')} />}
            <Tab label={getResourceLabel('sales')}>
                <SalesReferenceDatagrid target={target} />
            </Tab>
            {isLogsEnabled && (
                <Tab label={getResourceLabel('log_entries')}>
                    <Logs hideRecordTitle translateResource="reclamations" {...props} />
                </Tab>
            )}
        </TabbedShowLayout>
    );
};

export default ProductExpand;
