import { useRecordContext } from 'react-admin';

import DiffHtmlField from './DiffHtmlField';
import DataWithMessageField from './DataWithMessageField';

import { ACTION, LogEntry } from '@js/interfaces/logentry';

const DataField = ({ translateResource, ...props }: { translateResource?: string; record?: LogEntry }) => {
    const record = useRecordContext(props);

    const renderContent = () => {
        switch (record?.action) {
            case ACTION.SELLO_SYNC:
            case ACTION.WEB_HOOK:
            case ACTION.ACTION_GIAB_S_WEB_HOOK:
            case ACTION.ACTION_CHAT_GPT_COMPLETION:
                return <DataWithMessageField record={record} />;
            case ACTION.CREATE:
            case ACTION.UPDATE:
            case ACTION.REMOVE:
            case ACTION.PURCHASE_SEPARATELY:
                return <DiffHtmlField record={record} translateResource={translateResource} />;
            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};

export default DataField;
