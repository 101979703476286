import { useState } from 'react';

export type UseOptionalControlledOpenProps = {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    defaultOpen?: boolean;
};

const useOptionalControlledOpen = ({
    open: controlledOpen,
    onOpenChange,
    defaultOpen = false,
}: UseOptionalControlledOpenProps) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(defaultOpen);

    // Determine if the component is controlled
    const isControlled = controlledOpen !== undefined;
    const open = isControlled ? controlledOpen : uncontrolledOpen;

    const setOpen = (newOpen: boolean) => {
        if (isControlled) {
            if (!onOpenChange) throw new Error('onOpenChange is required when open is controlled');

            onOpenChange(newOpen);
        } else {
            setUncontrolledOpen(newOpen);
        }
    };

    return [open, setOpen] as const;
};

export default useOptionalControlledOpen;
