import { Tabs, Tab, Box, BoxProps } from '@material-ui/core';
import { FC, PropsWithChildren, useState } from 'react';
import { useGetResourceLabel, Identifier, Record } from 'react-admin';

import SalesReferenceDatagrid from '@components/resource/sales/SalesReferenceDatagrid';

import Logs from './Logs';
import CommentsForm from './CommentsForm';
import ReclamationEditableDatagrid from '../../ReclamationEditableDatagrid';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

type TabValue = 'reclamations' | 'comments' | 'log_entries' | 'sales';

interface TabPanelProps extends BoxProps {
    activeTab: TabValue;
    tab: TabValue;
}

export interface ExpandPanelProps {
    basePath: string;
    id: Identifier;
    record: Record;
    resource: string;
}

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, activeTab, tab, ...rest }) => {
    const isActive = activeTab === tab;

    return (
        <Box hidden={!isActive} {...rest}>
            {isActive && children}
        </Box>
    );
};

const ExpandPanel: FC<ExpandPanelProps> = (props) => {
    const [activeTab, setActiveTab] = useState<TabValue>('reclamations');
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();

    const commentsEnabled = permissions.isResourceEnabled('comments');
    const logEntriesEnabled = permissions.isResourceEnabled('log_entries');
    const salesEnabled = permissions.isResourceEnabled('sales');

    return (
        <>
            <Tabs value={activeTab} onChange={(_, activeTab) => setActiveTab(activeTab)}>
                <Tab value="reclamations" label={getResourceLabel('reclamations')} />
                {commentsEnabled && <Tab value="comments" label={getResourceLabel('comments')} />}
                {salesEnabled && <Tab value="sales" label={getResourceLabel('sales')} />}
                {logEntriesEnabled && <Tab value="log_entries" label={getResourceLabel('log_entries')} />}
            </Tabs>
            <TabPanel activeTab={activeTab} tab="reclamations" pl={1} pt={2} pb={2}>
                <ReclamationEditableDatagrid {...props} />
            </TabPanel>
            {commentsEnabled && (
                <TabPanel activeTab={activeTab} tab="comments" pl={3} pt={1}>
                    <CommentsForm {...props} />
                </TabPanel>
            )}
            {salesEnabled && (
                <TabPanel activeTab={activeTab} tab="sales" p={2}>
                    <SalesReferenceDatagrid {...props} target="reclamation.errand" />
                </TabPanel>
            )}
            {logEntriesEnabled && (
                <TabPanel activeTab={activeTab} tab="log_entries" p={1}>
                    <Logs {...props} />
                </TabPanel>
            )}
        </>
    );
};

export default ExpandPanel;
