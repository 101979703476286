import { DateField, ListProps, ReferenceArrayField, SelectField, SingleFieldList } from 'react-admin';

import CopyableTextField from '@js/components/field/CopyableTextField';
import FilePreviewField from '@components/field/FilePreviewField';
import StatusesHistory from '@components/field/StatusesHistory';
import StatusPreviewField from '@components/resource/errand/field/StatusPreviewField';
import LastSaleSoldAtField from '@components/resource/reclamation/field/LastSaleSoldAtField';

import Aside from './Aside';
import SimpleProductList from './SimpleProductList';
import BundleProductList from './BundleProductList';
import QuantityField from './field/QuantityField';

import { SplitTypes } from '@js/interfaces/product';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useListView from './useListView';

const SPLIT_TYPE_CHOICES = SplitTypes.map((type) => ({ id: type, name: `resources.products.splitType.${type}` }));

export default function ProductList(props: ListProps) {
    const [list, setList] = useListView();
    const getFieldLabel = useTranslateResourceField('reclamations');
    const lists = {
        simple: SimpleProductList,
        bundle: BundleProductList,
    };
    const List = lists[list];

    return (
        <List {...props} aside={<Aside activeList={list} onListChange={setList} />} syncWithLocation={false}>
            {/* This is common fields for both Lists */}
            <SelectField choices={SPLIT_TYPE_CHOICES} source="splitType" sortable={false} />
            <DateField source="selloSyncedAt" showTime sortable={false} />
            <QuantityField source="quantity" label={getFieldLabel('quantity')} />
            <CopyableTextField source="composedIdentifier" label={getFieldLabel('composedIdentifier')} />
            <ReferenceArrayField
                reference="reclamation_media_objects"
                source="images"
                label={getFieldLabel('images')}
                sortable={false}
            >
                <SingleFieldList linkType={false}>
                    <FilePreviewField source="paths.publicPath" />
                </SingleFieldList>
            </ReferenceArrayField>
            <StatusPreviewField
                source="status"
                history={<StatusesHistory target="product" reference="product_statuses" />}
                disableNotifications
            />
            <LastSaleSoldAtField source="lastSaleSoldAt" label={getFieldLabel('lastSaleSoldAt')} />
        </List>
    );
}
