import { createContext, ReactNode, useContext, useMemo } from 'react';
import { usePermissions, useResourceContext } from 'react-admin';

import UserPermissionsService, {
    EditAction,
    FilterDefaultValue,
    ListAction,
    ResourceFeatures,
} from '@js/auth/UserPermissionsService';

type UserPermissionsContextValue = UserPermissionsService;

const UserPermissionsContext = createContext<UserPermissionsContextValue>(undefined!);

export const UserPermissionsContextProvider = ({ children }: { children: ReactNode }) => {
    const { permissions } = usePermissions();
    if (!permissions) return null;

    return <UserPermissionsContext.Provider value={permissions}>{children}</UserPermissionsContext.Provider>;
};

export const useUserPermissions = (): UserPermissionsContextValue => {
    return useContext(UserPermissionsContext);
};

/**
 * Helper hook to get resource permissions
 */
export const useResourcePermissions = (props?: { resource?: string }) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);

    return useMemo(
        () => ({
            isListActionEnabled: (action: ListAction) => permissions.isListActionEnabled(resource, action),
            isFieldListable: (field: string) => permissions.isFieldListable(resource, field),
            isFieldEditable: (field: string) => permissions.isFieldEditable(resource, field),
            isEditActionEnabled: (action: EditAction) => permissions.isEditActionEnabled(resource, action),
            isFilterAlwaysOn: (filter: string) => permissions.isFilterAlwaysOn(resource, filter),
            getFilterDefaultValue: (filter: string) => permissions.getFilterDefaultValue(resource, filter),
        }),
        [permissions, resource],
    );
};

export const useResourceFilterDefaultValues = (filters: string[], props?: { resource?: string }) => {
    const { getFilterDefaultValue } = useResourcePermissions(props);

    return filters.reduce((acc, filter) => {
        const defaultValue = getFilterDefaultValue(filter);
        if (defaultValue) {
            acc[filter] = defaultValue;
        }
        return acc;
    }, {} as Record<string, FilterDefaultValue>);
};

export const useResourceFeatures = <T extends keyof ResourceFeatures>(resource: T) => {
    const permissions = useUserPermissions();

    return permissions.getFeatureFlags(resource);
};
