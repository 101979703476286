import { useMemo } from 'react';
import {
    AutocompleteInput,
    DateTimeInput,
    maxValue,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    useCreate,
    useGetOne,
    useGetResourceLabel,
    useNotify,
    useTranslate,
} from 'react-admin';
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { Form, useField } from 'react-final-form';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ContentAdd from '@material-ui/icons/Add';

import LoadingButton from '@components/button/LoadingButton';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import CurrencySelectInput from '@components/input/CurrencySelectInput';

import decimal from '@js/validator/decimal';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

import { ManualSale } from '@js/interfaces/ManualSale';
import { Reclamation } from '@js/interfaces/reclamation';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Sale } from '@js/interfaces/Sale';

type Props = {
    open: boolean;
    onClose: () => void;
    reclamationFilter?: Record<string, string | number>;
    postCreate?: () => void;
};

const ManualSaleDialogForm = ({ open, onClose, reclamationFilter, postCreate }: Props) => {
    const fullScreen = useIsSmallScreen();

    return (
        <MuiDialog open={open} fullScreen={fullScreen} maxWidth="md" fullWidth onClose={onClose}>
            <DialogForm onClose={onClose} postCreate={postCreate} reclamationFilter={reclamationFilter} />
        </MuiDialog>
    );
};

const DialogForm = ({
    onClose,
    postCreate,
    reclamationFilter,
}: Pick<Props, 'onClose' | 'postCreate' | 'reclamationFilter'>) => {
    const translate = useTranslate();
    const [create] = useCreate();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const getResourceLabel = useGetResourceLabel();

    const resource = 'sales';
    const getFieldLabel = useTranslateResourceField(resource);

    const handleSubmit = async (data: Omit<ManualSale, 'id' | '@id'>) => {
        return create(resource, data, {
            onSuccess: () => {
                onClose();
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });
                if (postCreate) postCreate();
            },
            onFailure: notifyError,
            returnPromise: true,
        });
    };

    const memoOptionText = useMemo(() => <ReclamationOptionRenderer />, []);
    const type: Sale['type'] = 'manual';

    return (
        <Form onSubmit={handleSubmit} initialValues={{ type }}>
            {(formProps) => {
                return (
                    <>
                        <DialogContent>
                            <ReferenceInput
                                reference="reclamations"
                                source="reclamation"
                                resource={resource}
                                label={getResourceLabel('reclamations')}
                                filter={reclamationFilter}
                                validate={required()}
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText={memoOptionText}
                                    inputText={reclamationToText}
                                    matchSuggestion={() => true}
                                />
                            </ReferenceInput>
                            <NumberInput
                                source="salesPricePerItem"
                                label={getFieldLabel('salesPrice')}
                                step="0.01"
                                validate={[decimal(), required(), minValue(1)]}
                                fullWidth
                                resource={resource}
                                helperText={translate('app.form.helperText.price_per_item_input')}
                            />
                            <CurrencySelectInput
                                source="currency"
                                validate={required()}
                                resource={resource}
                                fullWidth
                            />
                            <QuantityInput resource={resource} />
                            <AutoCompleteGuesserInput
                                source="saleChannel"
                                validate={required()}
                                fullWidth
                                resource={resource}
                            />
                            <DateTimeInput source="soldAt" validate={required()} fullWidth resource={resource} />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="primary">
                                {translate('ra.action.close')}
                            </Button>
                            <LoadingButton
                                label="ra.action.create"
                                loading={formProps.submitting}
                                icon={<ContentAdd />}
                                onClick={formProps.handleSubmit}
                            />
                        </DialogActions>
                    </>
                );
            }}
        </Form>
    );
};

const QuantityInput = ({ resource }: { resource: string }) => {
    const {
        input: { value: reclamationIri },
    } = useField('reclamation', { subscription: { value: true } });
    const { data: reclamation } = useGetOne<Reclamation>('reclamations', reclamationIri, { enabled: !!reclamationIri });
    const translate = useTranslate();

    return (
        <NumberInput
            source="quantity"
            step="1"
            validate={[required(), minValue(1), maxValue(reclamation?.inStockQuantity)]}
            fullWidth
            resource={resource}
            disabled={!reclamation}
            helperText={
                reclamation?.inStockQuantity && translate('app.label.max_value', { value: reclamation.inStockQuantity })
            }
        />
    );
};

const ReclamationOptionRenderer = ({ record, filterValue = '' }: { record?: Reclamation; filterValue?: string }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!record) return null;
    const text = reclamationToText(record);

    if (record.outOfStock) {
        return (
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className={classes.disabledOption}
            >
                <Typography className={classes.disabledOptionText}>{`${text} (${translate(
                    'app.label.out_of_stock',
                )})`}</Typography>
            </div>
        );
    }

    const matches = match(text, filterValue);
    const parts = parse(text, matches);

    return (
        <div className={classes.option}>
            {parts.map((part, index) => {
                return part.highlight ? <strong key={index}>{part.text}</strong> : <span key={index}>{part.text}</span>;
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    option: {
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        minHeight: 24,
    },
    disabledOption: {
        cursor: 'not-allowed',
    },
    disabledOptionText: {
        color: theme.palette.text.disabled,
    },
}));

const reclamationToText = (reclamation: Reclamation) => {
    if (!reclamation) return '';

    return `${reclamation.label} - ${reclamation.composedIdentifier}`;
};

export default ManualSaleDialogForm;
