import { GiabsSale } from '@js/interfaces/GiabsSale';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

import InfoSummary from './InfoSummary';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useFormatPrice } from '@js/hooks/useFormatPrice';

type Props = {
    source?: string;
    record?: GiabsSale;
    resource?: string;
    sortable?: boolean;
    addLabel?: boolean;
    label?: string;
};

const GiabsSaleAdditionalInfoField = (props: Props) => {
    const record = useRecordContext(props);
    const formatPrice = useFormatPrice(record?.currency);
    const getFieldLabel = useTranslateResourceField();

    if (!record) return null;

    const items = ['serviceFeePrice', 'mobileCirclePrice', 'deductibleArticlePrice', 'differencePrice', 'cost'].map(
        (property) => {
            return {
                label: getFieldLabel(property),
                value: formatPrice(get(record, property)),
            };
        },
    );

    return <InfoSummary items={items} />;
};

GiabsSaleAdditionalInfoField.defaultProps = {
    source: 'additionalInfo',
    sortable: false,
};

export default GiabsSaleAdditionalInfoField;
