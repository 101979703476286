import { ReactNode, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Identifier, Labeled, Record, useGetResourceLabel, useTranslate } from 'react-admin';

import GridShowLayout from '@components/detail/GridShowLayout';
import ReclamationFieldGuesser from './ReclamationFieldGuesser';
import SalesTab from './SalesTab';
import Logs from '../ErrandList/ExpandPanel/Logs';

import useShowFields from '@js/hooks/useShowFields';
import { extractFields } from '@components/resource/errand/util';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

interface ExpandShowPanelProps {
    resource?: string;
    hideFields: string[];
    basePath?: string;
    id?: Identifier;
    record?: Record;
}

type TabType = 'general' | 'sales' | 'log_entries';

interface TabPanelProps {
    activeTab: TabType;
    tab: TabType;
}

const TabPanel = ({ children, activeTab, tab }: TabPanelProps & { children: ReactNode }) => {
    const isActive = activeTab === tab;

    return <Box hidden={!isActive}>{isActive && children}</Box>;
};

const ExpandShowPanel = ({ hideFields, ...props }: ExpandShowPanelProps & { children: ReactNode }) => {
    const showFields = useShowFields(props, hideFields);
    const { rest: fields, extracted: excludeFromGridFields } = extractFields(showFields, ['images']);
    const [activeTab, setActiveTab] = useState<TabType>('general');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();

    const isLogsEnabled = permissions.isResourceEnabled('log_entries');
    const isSalesEnabled = permissions.isResourceEnabled('sales');

    return (
        <>
            <Tabs value={activeTab} onChange={(_, activeTab) => setActiveTab(activeTab)}>
                <Tab value="general" label={translate('resources.reclamations.tabs.general')} />
                {isSalesEnabled && <Tab value="sales" label={getResourceLabel('sales')} />}
                {isLogsEnabled && <Tab value="log_entries" label={getResourceLabel('log_entries')} />}
            </Tabs>
            <TabPanel activeTab={activeTab} tab="general">
                <GridShowLayout {...props}>
                    {fields.map((field) => (
                        <ReclamationFieldGuesser key={field.name} source={field.name} field={field} />
                    ))}
                </GridShowLayout>
                {excludeFromGridFields.map((field) => (
                    <Labeled key={field.name} source={field.name} resource={props.resource}>
                        <ReclamationFieldGuesser source={field.name} field={field} />
                    </Labeled>
                ))}
            </TabPanel>
            {isSalesEnabled && (
                <TabPanel activeTab={activeTab} tab="sales">
                    <SalesTab />
                </TabPanel>
            )}
            {isLogsEnabled && (
                <TabPanel activeTab={activeTab} tab="log_entries">
                    <Logs hideRecordTitle {...props} />
                </TabPanel>
            )}
        </>
    );
};

export default ExpandShowPanel;
