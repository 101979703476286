import { useState } from 'react';
import { useNotify } from 'react-admin';
import SyncIcon from '@material-ui/icons/Sync';

import IconButton from '@components/button/IconButton';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

type Props = {
    record: SelloSale | SelloOrderRow;
};

const ResyncSelloOrderButton = ({ record: { selloOrderId, country } }: Props) => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const notifyError = useNotifyHttpError();

    const handleClick = () => {
        if (loading) return;
        setLoading(true);

        post<{ message: 'ok' }>('/api/sello/resync_order', {
            body: JSON.stringify({ selloOrderId, country }),
        })
            .then(() => {
                notify('app.label.done', { type: 'info' });
            })
            .catch(notifyError)
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <IconButton label="app.action.resync" onClick={handleClick} loading={loading}>
            <SyncIcon />
        </IconButton>
    );
};

export default ResyncSelloOrderButton;
