import { Reclamation } from '@js/interfaces/reclamation';
import SalesReferenceDatagrid from '@components/resource/sales/SalesReferenceDatagrid';
import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';

type Props = {
    record?: Reclamation;
    resource?: string;
};

const SalesTab = (props: Props) => {
    const { filter, warning } = useSalesContextFilter();

    return <SalesReferenceDatagrid {...props} filter={filter} filterWarning={warning} />;
};

export default SalesTab;
