import { NumberField, useRecordContext } from 'react-admin';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const QuantityField = (props: Props) => {
    const record = useRecordContext(props);

    if (!record) return null;

    switch (record.type) {
        case 'sello':
            return <NumberField {...props} source="rowQuantity" />;
        default:
            return <NumberField {...props} />;
    }
};

QuantityField.defaultProps = {
    source: 'quantity',
    sortable: false,
};

export default QuantityField;
