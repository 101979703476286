import { DateField, useRecordContext, useTranslate } from 'react-admin';
import { makeStyles, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import useFormatSecondsElapsed from '@js/hooks/useFormatSecondsElapsed';

import { Reclamation } from '@js/interfaces/reclamation';
import { Product } from '@js/interfaces/product';

type RecordItem = Reclamation | Product;

type Props = {
    source: string;
    record?: RecordItem;
    resource?: string;
    label?: string;
};

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', alignItems: 'center' },
    span: {
        marginLeft: theme.spacing(0.5),
        lineHeight: '1',
        '& .MuiSvgIcon-root': {
            fontSize: 18,
        },
    },
}));

const LastSaleSoldAtField = (props: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const classes = useStyles();
    const formatSecondsElapsed = useFormatSecondsElapsed({ excludeAgo: true, excludeIntervals: ['second'] });

    if (!record?.averageSaleTime && record?.averageSaleTime !== 0) return null;

    return (
        <div className={classes.root}>
            <DateField {...props} showTime />
            <span className={classes.span}>
                <Tooltip
                    title={`${translate('app.label.average_sales_time')}: ${formatSecondsElapsed(
                        record.averageSaleTime,
                    )}`}
                >
                    <InfoIcon fontSize="small" color="secondary" />
                </Tooltip>
            </span>
        </div>
    );
};

export default LastSaleSoldAtField;
