import { ReactNode } from 'react';
import { Datagrid, DateField, ReferenceManyField, ReferenceManyFieldProps } from 'react-admin';
import { colors as muiColors } from '@material-ui/core';

import AutoHidePagination from '@components/list/AutoHidePagination';
import PassthroughProps from '@components/PassthroughProps';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';

import ExpandRow, { isRowExpandable } from './ExpandRow';
import RowActions from './RowActions';
import StatusField from './field/StatusField';
import TypeField from './field/TypeField';
import QuantityField from './field/QuantityField';
import SalesPriceField from './field/SalesPriceField';
import AdditionalInfoField from './field/AdditionalInfoField';

import { SelloSale } from '@js/interfaces/selloSale';
import { Sale, Status } from '@js/interfaces/Sale';

type Props = Omit<ReferenceManyFieldProps, 'sort' | 'target' | 'reference' | 'children'> & {
    filterWarning?: ReactNode;
    target?: string;
};

const SalesReferenceDatagrid = ({ filterWarning, ...props }: Props) => {
    return (
        <ReferenceManyField
            perPage={10}
            pagination={<AutoHidePagination />}
            target="reclamation"
            {...props}
            sort={{ field: 'soldAt', order: 'desc' }}
            reference="sales"
        >
            <PassthroughProps<{ record?: SelloSale }>>
                {(props) => (
                    <>
                        {filterWarning}
                        <Datagrid
                            {...props}
                            empty={<AlertEmptyResource mb={2} />}
                            isRowExpandable={isRowExpandable}
                            expand={<ExpandRow />}
                            rowStyle={rowStyle}
                        >
                            <DateField source="soldAt" showTime />
                            <TypeField />
                            <StatusField />
                            <QuantityField />
                            <SalesPriceField />
                            <ReferenceFieldGuesser source="saleChannel" sortable={false} asText />
                            <AdditionalInfoField />
                            <RowActions />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

export const rowStyle = (record: any) => {
    const status = (record as Sale | undefined)?.status;
    const mapping: { [key in Status]?: string } = {
        canceled: muiColors.deepOrange[50],
        pending: muiColors.yellow[50],
        deleted: muiColors.red[100],
    };

    if (status && status in mapping) {
        return { backgroundColor: mapping[status] };
    }
};

export default SalesReferenceDatagrid;
