import { Identifier, useTranslate } from 'react-admin';

import useGetLockRecord from '@js/hooks/useGetLockRecord';
import useFormatTimeElapsed from '@js/hooks/useFormatTimeElapsed';

const useLockRecordWarningMessage = (id: Identifier | null | undefined) => {
    const lockRecord = useGetLockRecord(id?.toString());
    const translate = useTranslate();
    const formatAgo = useFormatTimeElapsed();

    if (!lockRecord?.isValid()) return null;

    return translate('app.message.record_locked', {
        username: lockRecord.username,
        time: formatAgo(lockRecord.createdAt),
    });
};

export default useLockRecordWarningMessage;
