import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

type Props = {
    items: Array<{
        label: string;
        value: ReactNode;
    }>;
};

const InfoSummary = ({ items }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {items.map((item, index) => {
                return (
                    <span key={index} className={classes.flex}>
                        <b className={classes.label}>{item.label}:</b>
                        <span>{item.value}</span>
                    </span>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginRight: theme.spacing(1),
    },
    flex: {
        display: 'flex',
    },
}));

export default InfoSummary;
