import { ReactNode, useState } from 'react';
import {
    Datagrid,
    DatagridProps,
    DateField,
    ReferenceManyField,
    TextField,
    useListContext,
    useLocale,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton as MuiIconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { chunk } from 'lodash';
import { useField } from 'react-final-form';

import OpacityIcon from '@material-ui/icons/Opacity';
import CloseIcon from '@material-ui/icons/Close';
import NavigationRefresh from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';

import LoadingButton from '@components/button/LoadingButton';
import IconButton from '@components/button/IconButton';
import AutoHidePagination from '@components/list/AutoHidePagination';
import FilterForm from '@components/Dashboard/FilterForm';
import PriceField from '@components/field/PriceField';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import FunctionField from '@components/field/FunctionField';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { Locale, useIsFeatureDisabled } from '@js/context/AppConfigContext';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';

import { post } from '@js/request/apiRequest';
import { Reclamation } from '@js/interfaces/reclamation';
import { SavingRecord, SavingType } from '@js/interfaces/Saving';

type Props = {
    record?: Reclamation;
};

type FilterValue = { type?: SavingType };

const Co2CalculatorButton = (props: Props) => {
    const isFeatureDisabled = useIsFeatureDisabled();

    if (isFeatureDisabled('Co2')) return null;

    return <Co2CalculatorButtonView {...props} />;
};

const Co2CalculatorButtonView = (props: Props) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const hasCategory = !!record?.category;

    return (
        <>
            <IconButton
                label="app.action.co2"
                disabledReason={hasCategory ? null : 'Category is required'}
                onClick={() => setOpen(true)}
            >
                <OpacityIcon />
            </IconButton>
            {hasCategory && <DialogInfo open={open} record={record} onClose={() => setOpen(false)} />}
        </>
    );
};

const useDialogInfoStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        zIndex: 2,
    },
}));

const DialogInfo = ({ record, open, onClose }: { record: Reclamation; open: boolean; onClose: () => void }) => {
    const [filters, setFilters] = useState<FilterValue>({ type: SavingType.IncludePurchasePrice });
    const isSmallScreen = useIsSmallScreen();
    const translate = useTranslate();

    const classes = useDialogInfoStyles();

    return (
        <Dialog fullWidth fullScreen={isSmallScreen} open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                {translate('app.action.co2')}
                <MuiIconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
                    <CloseIcon />
                </MuiIconButton>
            </DialogTitle>
            <ReferenceManyField
                reference="savings"
                target="reclamation"
                addLabel={false}
                pagination={<AutoHidePagination />}
                filter={filters}
            >
                <SavingsDatagrid reclamation={record} filters={filters} onFiltersChange={setFilters} />
            </ReferenceManyField>
        </Dialog>
    );
};

const SavingsDatagrid = ({
    reclamation,
    filters,
    onFiltersChange,
    ...props
}: DatagridProps & {
    reclamation: Reclamation;
    filters: FilterValue;
    onFiltersChange: (filters: FilterValue) => void;
}) => {
    const [refreshing, setRefreshing] = useState(false);
    const { refetch } = useListContext();
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();
    const notifyError = useNotifyHttpError();

    const handleRefresh = async () => {
        setRefreshing(true);

        try {
            await post<void>(`${reclamation.id}/recalculate_savings`);
            refetch();
        } catch (error) {
            notifyError(error);
        } finally {
            setRefreshing(false);
        }
    };

    return (
        <>
            <DialogContent>
                <FilterForm initialValues={filters} onChange={onFiltersChange} disableMinHeight>
                    <TypeSelect source="type" />
                </FilterForm>
                <Datagrid
                    {...props}
                    expand={<SavingsExpand />}
                    empty={<AlertEmptyResource />}
                    isRowExpandable={(record) => !record?.error}
                >
                    <FunctionField<SavingRecord>
                        label={''}
                        render={(record) => {
                            if (record.error) {
                                return (
                                    <Alert style={{ padding: '0px 6px' }} severity="warning">
                                        {record.error}
                                    </Alert>
                                );
                            }

                            return record.recipe;
                        }}
                    />
                    <FunctionField<SavingRecord>
                        label={translate('app.label.type')}
                        render={(record) => {
                            return translate(`app.co2.${record.type}`);
                        }}
                    />
                    <PriceField
                        source="salesPrice"
                        label={getFieldLabel('salesPrice', 'sello_sales')}
                        formatCents={false}
                    />
                    <TextField source="soldQuantity" label={getFieldLabel('quantity', 'reclamations')} />
                    <DateField source="createdAt" label={getFieldLabel('createdAt', 'reclamations')} showTime />
                </Datagrid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={refreshing}
                    onClick={handleRefresh}
                    label="ra.action.refresh"
                    icon={<NavigationRefresh />}
                />
            </DialogActions>
        </>
    );
};

const TypeSelect = (props: { source: string }) => {
    const { input } = useField(props.source);
    const translate = useTranslate();

    return (
        <FormControl>
            <InputLabel>{translate('app.label.type')}</InputLabel>
            <Select {...input}>
                {Object.values(SavingType).map((type) => (
                    <MenuItem key={type} value={type}>
                        {`${translate(`app.co2.${type}`)}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const SavingsExpand = (props: { record?: SavingRecord }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const features = useResourceFeatures('reclamations');
    const [tabValue, setTabValue] = useState(0);

    const showRawValues = !features.disableSavingsRawValues;
    const showAnalogies = !features.disableSavingsAnalogies;

    const renderAsTabs = showRawValues && showAnalogies;

    return renderAsTabs ? (
        <Box flexGrow={1}>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, newValue) => setTabValue(newValue)}
            >
                <Tab label={translate('app.label.values')} />
                <Tab label={translate('app.label.analogies')} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <SavingsRawValues savingRecord={record} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Analogies savingRecord={record} />
            </TabPanel>
        </Box>
    ) : (
        <>
            {showRawValues && <SavingsRawValues savingRecord={record} />}
            {showAnalogies && <Analogies savingRecord={record} />}
        </>
    );
};

const TabPanel = ({ children, value, index }: { children: ReactNode; value: number; index: number }) => {
    const isActive = value === index;

    return (
        <div role="tabpanel" hidden={!isActive}>
            {isActive && children}
        </div>
    );
};

const FormulaInfoIcon = ({ formula }: { formula: string | null }) => {
    const [show, setShow] = useState(false);

    if (!formula) return null;

    const handleClose = () => setShow(false);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <span>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleClose}
                    open={show}
                    title={formula}
                    placement="top"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <MuiIconButton onClick={() => setShow(!show)} size="small" disableRipple disableFocusRipple>
                        <InfoIcon fontSize="inherit" />
                    </MuiIconButton>
                </Tooltip>
            </span>
        </ClickAwayListener>
    );
};

const SavingsRawValues = ({ savingRecord }: { savingRecord: SavingRecord }) => {
    const translate = useTranslate();
    const chunks = chunk(Object.entries(savingRecord.savings), 5);

    return (
        <Grid container spacing={2}>
            {chunks.map((chunk, index) => {
                return (
                    <Grid key={index} item xs={12} md={3}>
                        <List>
                            {chunk.map(([saving, result]) => {
                                return (
                                    <ListItem key={saving} disableGutters>
                                        <ListItemText
                                            primary={translate(`app.co2.savings.${saving}`)}
                                            secondary={
                                                result?.value ? (
                                                    <>
                                                        {result.value}
                                                        <FormulaInfoIcon formula={result.formula} />
                                                    </>
                                                ) : (
                                                    translate('app.label.no_result')
                                                )
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const useAnalogiesStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

const Analogies = ({ savingRecord }: { savingRecord: SavingRecord }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useAnalogiesStyles();

    const analogies = savingRecord.analogies;
    const atLeastOneAnalogy = Object.values(analogies).some((value) => value.length > 0);

    if (!atLeastOneAnalogy) {
        return (
            <Box my={2}>
                <Alert severity="info">{translate('ra.page.not_found')}</Alert>
            </Box>
        );
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            {Object.entries(analogies).map(([saving, analogyList]) => {
                return analogyList.map((analogy) => {
                    const randomImage =
                        analogy.logos.length === 0
                            ? null
                            : analogy.logos.length > 1
                            ? analogy.logos[Math.floor(Math.random() * analogy.logos.length)]
                            : analogy.logos[0];
                    const description = analogy.description[locale as Locale] || analogy.description.en;

                    return (
                        <Grid key={analogy.id} item xs={12} sm={6} md={4} lg={3}>
                            <Card key={`${saving}-${analogy.id}`} className={classes.card}>
                                {randomImage && (
                                    <CardMedia
                                        image={randomImage.url}
                                        title={randomImage.title}
                                        className={classes.media}
                                    />
                                )}
                                <CardContent>
                                    <Typography variant="subtitle2" component="p">
                                        {translate(`app.co2.savings.${saving}`)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                });
            })}
        </Grid>
    );
};

export default Co2CalculatorButton;
