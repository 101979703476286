import { cacheDataProviderProxy, Identifier } from 'react-admin';
import {
    ApiDocumentationParserResponse,
    ApiPlatformAdminDataProvider,
    fetchHydra,
    hydraDataProvider as baseHydraDataProvider,
} from '@api-platform/admin';
import { Field, parseHydraDocumentation, Resource } from '@api-platform/api-doc-parser';

import { createFakeIdParameters } from './parameterHelper';
import { normalizeMediaObjectField } from './dataProviderNormalizers';
import { getOrFetchCurrentUser } from '@js/auth/authProvider';

import { ExtendedResourceFields } from '@js/global';
import { Sale } from '@js/interfaces/Sale';

interface NormalizableDataField extends Field {
    reference: Resource;
    normalizeData: (data: any) => Promise<Identifier | Identifier[] | null>;
}

const isNormalizableDataField = (field: Field | undefined): field is NormalizableDataField => {
    return typeof field === 'object' && typeof field.reference === 'object' && field.reference !== null;
};

const apiDocumentationParser = async (entrypoint: string): Promise<ApiDocumentationParserResponse> => {
    const currentUser = await getOrFetchCurrentUser();

    // If failed to get refresh token redirect to login
    if (!currentUser) {
        // Simulate unauthorized response
        return {
            api: {
                entrypoint,
                resources: [],
            },
            status: 401,
            response: new Response(),
        };
    }

    const [hydraDocumentation, extendDocs] = await Promise.all([
        parseHydraDocumentation(entrypoint),
        fetch(new URL(`${entrypoint}/extended-documentation`)).then(
            (response) => response.json() as Promise<Record<string, ExtendedResourceFields>>,
        ),
    ]);

    const simpleFileUploadFields: Record<string, string[]> = {
        errands: ['images'],
        user_groups: ['logo'],
        reclamations: ['images'],
        stores: ['images', 'files'],
        products: ['images'],
    };

    // Manually set parameters/normalizers of some resources
    hydraDocumentation.api.resources?.forEach((resource) => {
        // Add ID filter parameters to all resources.
        // All resources by default have ID filter \Corerely\ApiPlatformHelperBundle\Doctrine\Extension\IdentifierCollectionFilterExtension
        resource.parameters = createFakeIdParameters();
        resource.sortableFields = extendDocs[resource.name]?.sortableFields || [];
        resource.validationRules = extendDocs[resource.name]?.validationRules || {};

        // Normalize File Upload fields
        simpleFileUploadFields[resource.name]?.forEach((fieldName) => {
            const field = resource.fields?.find(({ name }) => name === fieldName);

            if (!isNormalizableDataField(field)) {
                throw new Error(
                    `Invalid upload-able field normalization configuration for resource "${resource.name}" and field "${fieldName}"`,
                );
            }

            field.normalizeData = async (data) => normalizeMediaObjectField(field.reference.url, data);
        });
    });

    return hydraDocumentation;
};

// https://api-platform.com/docs/admin/handling-relations/#embedded-relations
const useEmbedded = true;

const getDataProvider = (entrypoint: string, mercure: string) => {
    const hydraDataProvider = baseHydraDataProvider({
        entrypoint,
        httpClient: fetchHydra,
        apiDocumentationParser,
        useEmbedded,
        mercure: {
            hub: mercure,
        },
    });
    // Cache responses for 1 minute.
    const oneMinute = 60 * 1000;
    const cachedDataProvider = cacheDataProviderProxy(hydraDataProvider, oneMinute);

    return new Proxy(cachedDataProvider, {
        get: (target, name: string) => {
            // Modify create method to handle different resource types
            if (name === 'create') {
                const create: ApiPlatformAdminDataProvider['create'] = (resource, params) => {
                    let finalResource = resource;

                    /**
                     * Override the final resource if the resource is sales.
                     * So if a user trying to create manual sale,
                     * the resource will be manual_sales to get the final url like '/api/manual_sales',
                     * same for purchased_separately.
                     *
                     * When do fetch by '/api/sales' ApiPlatform return all sales types.
                     * When do put or delete, it's using record ID like '/api/manual_sale/1', so it works.
                     */
                    if (resource === 'sales') {
                        const type = params.data.type as Sale['type'] | undefined;
                        if (type) finalResource = `${type}_sales`;
                    }

                    return target[name](finalResource, params);
                };
                return create;
            }

            return target[name];
        },
    });
};

export default getDataProvider;
