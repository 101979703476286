import { useRecordContext } from 'react-admin';

import RecordPriceField from '@components/field/RecordPriceField';

import SelloSalesPriceField from './SelloSalesPriceField';
import GiabsSalePriceField from './GiabsSalePriceField';

import { Sale } from '@js/interfaces/Sale';

type Props = {
    source?: string;
    currencyField?: string;
    record?: Sale;
    resource?: string;
    sortable?: boolean;
    label?: string;
};

const defaultProps: Required<Pick<Props, 'source' | 'currencyField' | 'sortable'>> = {
    source: 'salesPrice',
    currencyField: 'currency',
    sortable: false,
};

const SalesPriceField = (props: Props) => {
    const record = useRecordContext(props);
    const finalProps = { ...defaultProps, ...props };

    if (!record) return null;

    switch (record.type) {
        case 'sello':
            return <SelloSalesPriceField {...finalProps} record={record} />;
        case 'giab_s':
            return <GiabsSalePriceField {...finalProps} record={record} />;
        default:
            return <RecordPriceField {...finalProps} record={record} />;
    }
};

SalesPriceField.defaultProps = defaultProps;

export default SalesPriceField;
