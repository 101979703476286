import { FC, memo } from 'react';
import { Identifier } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import ReclamationInputGuesser from './input/ReclamationInputGuesser';
import GridFormLayout from '@components/form/GridFormLayout';

import useFormFields from '@js/hooks/useFormFields';
import { extractFields } from '@components/resource/errand/util';
import { Reclamation } from '@js/interfaces/reclamation';

interface Props {
    record?: Reclamation;
    resource?: string;
    id?: Identifier;
    disablePrint?: boolean;
}

const ReclamationForm: FC<Props> = ({ disablePrint, ...props }) => {
    const hideFields = ['material'];
    if (disablePrint) {
        hideFields.push('printLabel');
    }

    const fields = useFormFields(props, hideFields);
    const { rest, extracted } = extractFields(fields, [
        'classificationComment',
        'classificationOutComment',
        'images',
        'links',
        'internalFaultDescription',
        'internalComment',
        'shortDescription',
        'longDescription',
    ]);

    const renderGuesserInput = (field: Field) => (
        <ReclamationInputGuesser
            key={field.name}
            source={field.name}
            field={field}
            resource={props.resource}
            fullWidth
        />
    );

    return (
        <>
            <GridFormLayout>{rest.map(renderGuesserInput)}</GridFormLayout>
            {extracted.map(renderGuesserInput)}
        </>
    );
};

ReclamationForm.defaultProps = {
    resource: 'reclamations',
};

const PureReclamationForm = memo(ReclamationForm, (prevProps, nextProps) => {
    return (
        prevProps.id === nextProps.id &&
        prevProps.record === nextProps.record &&
        prevProps.resource === nextProps.resource &&
        prevProps.disablePrint === nextProps.disablePrint
    );
});

export default PureReclamationForm;
