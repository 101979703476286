import { LinearProgress, useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFetchGet from '@js/hooks/useFetchGet';

import { LogEntry } from '@js/interfaces/logentry';

interface Props {
    source?: string;
    addLabel?: boolean;
    record: LogEntry;
    translateResource?: string;
}

const useStyles = makeStyles({
    root: {
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        display: 'table',
    },
    item: {
        display: 'table-row',
    },
    titleResultCommon: {
        padding: '3px 10px 2px',
        display: 'table-cell',
        fontSize: '12px',
        lineHeight: '18px',
    },
    title: {
        backgroundColor: '#fafafa',
        fontStyle: 'italic',
        whiteSpace: 'nowrap',
    },
    result: {
        width: '100%',

        '& del': {
            color: '#c80c05',
            backgroundColor: '#fff6f6',
            textDecoration: 'none',
        },
        '& ins': {
            color: '#44a512',
            backgroundColor: '#ebfce3,',
            textDecoration: 'none',
        },
    },
});

const DiffHtmlField = ({ record: { id, resource }, translateResource }: Props) => {
    const translateField = useTranslateResourceField(translateResource || resource);
    const translate = useTranslate();
    const classes = useStyles();
    const { data: diffHtmlData, loading } = useFetchGet<Record<string, string>>(`${id.toString()}/diff_html`);

    if (loading) {
        return <LinearProgress />;
    }

    if (!diffHtmlData) {
        return <ErrorIcon aria-errormessage="HTTP error" role="presentation" color="error" fontSize="small" />;
    }

    const fields = Object.keys(diffHtmlData);

    if (fields.length === 0) {
        return <Alert severity="info">{translate('app.message.no_changes_in_record')}</Alert>;
    }

    return (
        <div className={classes.root}>
            {fields
                .filter((field) => diffHtmlData[field])
                .map((field) => {
                    const html = diffHtmlData[field];
                    return (
                        <div key={field} className={classes.item}>
                            <div className={`${classes.title} ${classes.titleResultCommon}`}>
                                {translateField(field)}
                            </div>
                            <div
                                className={`${classes.result} ${classes.titleResultCommon}`}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

DiffHtmlField.defaultProps = {
    addLabel: true,
    source: 'diffHtml',
};

export default DiffHtmlField;
