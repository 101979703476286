import { Iri } from '@js/interfaces/ApiRecord';
import { useState } from 'react';
import { Reclamation } from '@js/interfaces/reclamation';
import {
    Identifier,
    LinearProgress,
    maxValue,
    minValue,
    NumberInput,
    required,
    useGetOne,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { cloneReclamation, EXCLUDE_DEFAULT_RECLAMATION_FIELDS } from '@js/utility/cloneUtil';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Form, useField, useForm } from 'react-final-form';
import CheckboxInput from '@components/input/CheckboxInput';
import useDidMountEffect from '@js/hooks/useDidMountEffect';
import ReclamationForm from '@components/resource/reclamation/ReclamationForm';
import FormController from '@components/resource/errand/ReclamationsEditTab/FormController';
import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

type Props = {
    cloneId: Iri;
    onCancel: () => void;
    onSuccess?: () => void;
    registerForm: (id: Identifier) => void;
    unregisterForm: (id: Identifier) => void;
};

type CloneFormValues = {
    subtractQuantity: boolean;
    printLabel: boolean;
    printQuantitySameAsCloneQuantity: boolean;
    splitIntoSeparateArticles: boolean;
    data: Pick<Reclamation, 'quantity'>;
    includeClassificationOutComment: boolean;
};

const CloneForm = ({ cloneId, onSuccess, onCancel, ...rest }: Props) => {
    const [openCloneDialog, setOpenCloneDialog] = useState(true);
    const [cloneInitialValues, setCloneInitialValues] = useState<Partial<Reclamation>>();
    const [cloneFormSettings, setCloneFormSettings] = useState<Omit<CloneFormValues, 'data'>>();

    const { data: cloneRecord } = useGetOne<Reclamation>('reclamations', cloneId);
    const translate = useTranslate();
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const refresh = useRefresh();

    if (!cloneRecord) return <LinearProgress />;

    const handleSubmit = async (data: Partial<Reclamation>) => {
        const submitData = {
            ...cloneFormSettings,
            data,
        };

        try {
            const { clonesCreated } = await post<{ clonesCreated: number }>(`${cloneId}/clone`, {
                body: JSON.stringify(submitData),
            });

            notify('app.message.created', {
                type: 'info',
                messageArgs: { smart_count: clonesCreated },
            });

            refresh();
            onSuccess?.();
        } catch (error) {
            notifyFailure(error);
        }
    };

    const handleCloneConfirm = ({ data, ...settings }: CloneFormValues) => {
        // @see also \App\Controller\Reclamation\SuggestAction::ignoredFields.
        // Maybe it should be identical and we should have one source of truth.
        const exclude: Array<keyof Reclamation> = [
            ...EXCLUDE_DEFAULT_RECLAMATION_FIELDS,
            'quantity',
            'sku',
            'serialNumber',
            'defect',
            'grade',
            'type',
            'state',
            'functionality',
            'status',
            'cost',
            'processingTimes',
            'costRedeemedItem',
            'recyclingFee',
            'sparePart',
            'costSparePart',
            'uniqueId',
            'damageNumber',
            'seizureNumber',
            'location',
            'classificationComment',
            'internalFaultDescription',
            'internalComment',
        ];
        if (!settings.includeClassificationOutComment) {
            exclude.push('classificationOutComment');
        }

        const cloned = {
            ...cloneReclamation(cloneRecord, exclude),
            ...data,
        };

        setCloneInitialValues(cloned);
        setOpenCloneDialog(false);
        setCloneFormSettings(settings);
    };

    const handleDialogCancel = () => {
        setOpenCloneDialog(false);
    };

    const handleExited = () => {
        if (!cloneInitialValues) {
            onCancel();
        }
    };

    const maxQuantity = cloneRecord.quantity - 1;

    return (
        <>
            {cloneInitialValues && (
                <FormController
                    {...rest}
                    initialValues={cloneInitialValues}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                    disablePristine
                    validate={(values) => {
                        if (!cloneFormSettings?.subtractQuantity) return;

                        if (maxQuantity < values.quantity) {
                            return { quantity: translate('ra.validation.maxValue', { max: maxQuantity }) };
                        }
                    }}
                >
                    <ReclamationForm disablePrint />
                </FormController>
            )}
            <CloneDialog
                open={openCloneDialog}
                maxQuantity={maxQuantity}
                onCancel={handleDialogCancel}
                onCloneConfirm={handleCloneConfirm}
                onExited={handleExited}
            />
        </>
    );
};

const CloneDialog = ({
    open,
    maxQuantity,
    onCancel,
    onCloneConfirm,
    onExited,
}: {
    open: boolean;
    maxQuantity: number;
    onCancel: () => void;
    onCloneConfirm: (cloneInitialValues: CloneFormValues) => void;
    onExited: () => void;
}) => {
    const translate = useTranslate();
    const isSmallScreen = useIsSmallScreen();

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            fullScreen={isSmallScreen}
            onClose={onCancel}
            TransitionProps={{ onExited }}
        >
            <Form<CloneFormValues>
                initialValues={{
                    data: { quantity: 1 },
                    subtractQuantity: false,
                    printLabel: false,
                    printQuantitySameAsCloneQuantity: false,
                    splitIntoSeparateArticles: false,
                    includeClassificationOutComment: false,
                }}
                onSubmit={onCloneConfirm}
                render={({ handleSubmit, values }) => (
                    <>
                        <DialogContent>
                            <CheckboxInput
                                source="subtractQuantity"
                                label={translate('app.label.quantity_subtract')}
                                fullWidth
                            />
                            <Box display="flex">
                                <CheckboxInput
                                    source="printLabel"
                                    label={translate('resources.reclamations.fields.printLabel')}
                                />
                                <CheckboxInput
                                    source="printQuantitySameAsCloneQuantity"
                                    label={translate('app.label.print_same_number_as_quantity')}
                                    disabled={!values.printLabel || values.splitIntoSeparateArticles}
                                />
                            </Box>
                            <CheckboxInput
                                source="splitIntoSeparateArticles"
                                label={translate('app.label.clone_single_article')}
                                fullWidth
                            />
                            <CheckboxInput
                                source="includeClassificationOutComment"
                                label={translate('app.label.include', {
                                    subject: `"${translate('resources.reclamations.fields.classificationOutComment')}"`,
                                })}
                                fullWidth
                            />
                            <QuantityInput
                                source="data.quantity"
                                maxQuantity={maxQuantity}
                                label={translate('resources.reclamations.fields.quantity')}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCancel} color="primary" size="small">
                                {translate('ra.action.cancel')}
                            </Button>
                            <Button onClick={handleSubmit} color="primary" variant="contained" size="small">
                                {translate('ra.action.confirm')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            />
        </Dialog>
    );
};

const QuantityInput = ({ maxQuantity, source, label }: { maxQuantity: number; source: string; label: string }) => {
    const {
        input: { value: subtractQuantityValue },
    } = useField('subtractQuantity', { subscription: { value: true } });
    const form = useForm();

    useDidMountEffect(() => {
        form.resetFieldState(source);
    }, [subtractQuantityValue, form]);

    return (
        <NumberInput
            label={label}
            source={source}
            fullWidth
            validate={[required(), minValue(1), maxValue(subtractQuantityValue ? maxQuantity : Infinity)]}
        />
    );
};

export default CloneForm;
