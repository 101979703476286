import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { useNotify, useRecordContext, useTranslate, useUpdate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import ChatGPTManagerButton from '@components/button/ChatGPTManagerButton';
import ReclamationInputGuesser from '../input/ReclamationInputGuesser';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

import { Reclamation } from '@js/interfaces/reclamation';

export type FormCompletionButtonHandle = {
    open: () => void;
    close: () => void;
};

// Same as enum from src/OpenAI/Reclamation/CompletionFieldEnum.php
type CompletionField = Extract<
    keyof Reclamation,
    'insurancePrice' | 'shortDescription' | 'longDescription' | 'weight' | 'measure'
>;

const COMPLETION_FIELDS: CompletionField[] = [
    'insurancePrice',
    'shortDescription',
    'longDescription',
    'weight',
    'measure',
];

const useDisableReason = ({ title, ean }: { title?: string; ean?: string }) => {
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();

    return (
        !title &&
        !ean &&
        `${translate('app.error.missing_required_fields')}: "${getFieldLabel('title')}, ${getFieldLabel('ean')}"`
    );
};

const FormCompletionButton: ForwardRefRenderFunction<{ open: () => void; close: () => void }> = (_, ref) => {
    const [open, setOpen] = useState(false);

    const record = useRecordContext();
    const form = useForm();
    const { values } = useFormState({ subscription: { values: true } });
    const { title, ean } = values ?? {};

    const notify = useNotify();
    const disabledReason = useDisableReason({ title, ean });

    const handleOpen = () => {
        if (disabledReason) return;
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const handleCompletion = async (data: Record<CompletionField, string>) => {
        form.batch(() => {
            for (const [field, value] of Object.entries(data)) {
                form.change(field, value);
            }
        });
        notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
        });
    };

    return (
        <ChatGPTManagerButton
            open={open}
            onOpenChange={(open) => (open ? handleOpen() : handleClose())}
            createGetCompletionPayload={(completionFields) => ({
                title,
                ean,
                completionFields,
                reclamation: record?.id,
            })}
            completionFields={COMPLETION_FIELDS}
            onCompletion={handleCompletion}
            disabledReason={disabledReason}
            inputComponent={ReclamationInputGuesser}
        />
    );
};

const RecordCompletionButton = (props: { resource?: string; record?: Reclamation }) => {
    const record = useRecordContext(props);
    const [update] = useUpdate('reclamations', record?.id);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();

    const disabledReason = useDisableReason(record ?? {});
    const fields: CompletionField[] = ['insurancePrice', 'shortDescription', 'longDescription', 'weight', 'measure'];

    const handleCompletion = async (data: Record<CompletionField, string>) => {
        await update(
            { payload: { data } },
            {
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                },
                onFailure: notifyFailure,
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    return (
        <ChatGPTManagerButton
            createGetCompletionPayload={(completionFields) => ({ completionFields, reclamation: record?.id })}
            completionFields={fields}
            onCompletion={handleCompletion}
            disabledReason={disabledReason}
            inputComponent={ReclamationInputGuesser}
        />
    );
};

const ChatGPTCompletions = {
    FormCompletionButton: forwardRef(FormCompletionButton),
    RecordCompletionButton,
};

export default ChatGPTCompletions;
