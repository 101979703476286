import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import IconButton from '@components/button/IconButton';
import ManualSaleDialogForm from '@components/resource/manual_sales/ManualSaleDialogForm';

import { Errand } from '@js/interfaces/errand';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

type Props = { record?: Errand; resource?: string };

const ManualSaleButtonView = (props: Props) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const reclamationsCount = record?.reclamationsCount || 0;

    return (
        <>
            <IconButton
                label="app.action.manual_sale"
                disabledReason={reclamationsCount <= 0 && 'app.label.missing_reclamations'}
                onClick={() => setOpen(true)}
            >
                <MonetizationOnIcon />
            </IconButton>
            <ManualSaleDialogForm
                open={open}
                onClose={() => setOpen(false)}
                reclamationFilter={{ errand: record?.id }}
            />
        </>
    );
};

export default function ManualSaleButton(props: Props) {
    if (useIsFeatureDisabled()('ManualSale')) return null;

    return <ManualSaleButtonView {...props} />;
}
