import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import SettingsList from './SettingsList';
import SettingsEdit from './SettingsEdit';

import { RegisterResource } from '@components/ResourceGuesser';
import { Settings } from '@js/interfaces/settings';

export default {
    icon: SettingsApplicationsIcon,
    list: SettingsList,
    show: null,
    create: null,
    edit: SettingsEdit,
} satisfies RegisterResource<Settings>;
